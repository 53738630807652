import EventBus from '@/util/EventBus'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import getEnv from '@/util/env'
import TrackingEventsService from '@/services/tracking-events.service'
import StatsService from '@/services/stats.service'

export default {
  props: ['channel', 'campaignName', 'sendingDetails', 'mode'],
  components: { vMultiselectListbox },
  name: 'ConfirmCustomFieldsModal',
  data: function () {
    return {
      trackingEvents: [],
      showModal: false,
      loading: false,
      actionMode: 'remarketing',
      columns: [],
      customFields: [],
      customizeFields: false,
      eventPrefix: 'events',
      inverseEvents: [],
      hasInverseEvents: false,
      loadedColumns: false,
      selectedInverseEvents: [],
      trackingEventsSource: [],
      totalEventValues: null,
      disabledCreate: true,
    }
  },
  computed: {
    isNotBasic () {
      return this.sendingDetails.campaignType !== 'basic'
    },
    isSMS () {
      return this.channel === 'sms'
    },
    isUserColombia () {
      return this.$store.getters['auth/getUser'].isoCountry === 'CO' ?? JSON.parse(localStorage.getItem('user').isoCountry) === 'CO'
    },
  },
  methods: {
    createCampaign () {
      const that = this
      if ((this.hasInverseEvents && this.selectedInverseEvents.length < 1) || (!this.hasInverseEvents && this.sendingDetails.events.length < 1)) {
        EventBus.$emit('showAlert', 'danger', 'Debes seleccionar por lo menos un evento')
        return
      }
      if (that.channel === 'whatsapp') {
        StatsService.getContactsFiltered(this.getParams(), that.channel).then((contactsInfo) => {
          localStorage.setItem('contact-phones-new-campaign', JSON.stringify(contactsInfo))
          EventBus.$emit('newWhatsappCampaign')
        })
      } else {
        StatsService.getContactsFiltered(this.getParams(), that.channel).then(function (contactsInfo) {
          localStorage.setItem('contact-phones-new-campaign', JSON.stringify(contactsInfo))
          that.$router.push('/campaign/' + that.channel + '/create' + ((that.channel === 'sms' || that.channel === 'mailing') ? '?type=basic' : ''))
        })
      }
    },

    downloadFile () {
      EventBus.$emit('hideAlert')
      if (this.sendingDetails.events.length < 1) {
        EventBus.$emit('showAlert', 'danger', 'Debes seleccionar por lo menos un evento')
        return
      }
      const share = this.$route.params.shareHash ? 'share/' : ''
      const url = `${getEnv('VUE_APP_API_URL')}api/${share}stats/${this.channel}/sending-export-csv?${this.getUrlParams()}`
      this.closeModal()
      window.location.href = url
    },
    getUrlParams () {
      return new URLSearchParams(this.getParams()).toString()
    },

    getParams () {
      const user = JSON.parse(localStorage.getItem('user'))
      let params = {
        sendingId: this.sendingDetails.sendingId,
        customFields: this.sendingDetails.customFields,
        initDate: this.sendingDetails.initDate,
        endDate: this.sendingDetails.endDate,
        sendingEvents: this.hasInverseEvents ? this.selectedInverseEvents : this.sendingDetails.events,
        sessions: this.sendingDetails.sessions,
        columns: [...new Set(this.sendingDetails.columns)],
        token: user ? user.token : null,
        shareHash: this.$route.params.shareHash,
      }

      if (this.hasInverseEvents) {
        params = {
          ...params,
          hasInverseEvents: this.hasInverseEvents,
        }
      }
      if (this.errorDescription) {
        params = {
          ...params,
          errorDescription: this.sendingDetails.errorDescription,
        }
      }

      return params
    },
    addCustomFieldsToColumns () {
      this.customFields.forEach(field => {
        this.columns.push({ text: field.text, value: field.value })
      })
    },

    setSmsColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'countryIso' },
        { text: this.$t('Fecha evento').toString(), value: 'createdAt' },
        { text: this.$t('External id').toString(), value: 'externalId' },
        { text: this.$t('Remitente').toString(), value: 'from' },
        { text: this.$t('Mensaje').toString(), value: 'message' },
        { text: this.$t('URL').toString(), value: 'URL' },
      ]
      this.addCustomFieldsToColumns()
    },

    setMailingColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Asunto').toString(), value: 'subject' },
        { text: this.$t('Remitente').toString(), value: 'from' },
        { text: this.$t('Replica').toString(), value: 'reply' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'countryIso' },
        { text: this.$t('Fecha evento').toString(), value: 'createdAt' },
        { text: this.$t('External id').toString(), value: 'externalId' },
        { text: this.$t('Browser').toString(), value: 'Browser' },
        { text: this.$t('OS').toString(), value: 'OS' },
        { text: 'IP', value: 'IP' },
        { text: this.$t('URL').toString(), value: 'URL' },
        { text: this.$t('Tipo de email').toString(), value: 'emailType' },
      ]
      this.addCustomFieldsToColumns()
    },

    setVoiceColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Remitente').toString(), value: 'from' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'country_iso' },
        { text: this.$t('Fecha evento').toString(), value: 'created_at' },
        { text: this.$t('External id').toString(), value: 'external_id' },
        { text: this.$t('Mensaje').toString(), value: 'message' },
        { text: this.$t('Duración (segundos)').toString(), value: 'duration' },
      ]
      this.addCustomFieldsToColumns()
    },

    setWhatsappColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Remitente').toString(), value: 'from' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'country_iso' },
        { text: this.$t('Fecha evento').toString(), value: 'created_at' },
        { text: this.$t('External id').toString(), value: 'external_id' },
        // { text: this.$t('Mensaje').toString(), value: 'message' },
      ]
      this.addCustomFieldsToColumns()
    },

    setInverseSmsColumns () {
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
          { id: 90, event: 'pending' },
          { id: 91, event: 'no_clicks' },
        ]), [
        'sent', 'delivered', 'undelivered', 'clicked', 'no_clicks', 'pending', 'rejected', 'expired', 'unsubscribed',
      ])
    },

    setInverseMailingColumns () {
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
          { id: 92, event: 'not_received' },
          { id: 93, event: 'unopened' },
          { id: 91, event: 'no_clicks' },
        ]), [
        'sent', 'delivered', 'not_received', 'opened', 'unopened',
        'clicked', 'no_clicks', 'soft_bounced', 'hard_bounced', 'unsubscribed',
      ])
    },

    setInverseVoiceColumns () {
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
          { id: 94, event: 'unattended' },
        ]), [
        'sent', 'delivered', 'unattended', 'expired', 'unsubscribed',
      ])
    },

    setInverseWhatsappColumns () {
        // this.trackingEvents.concat([
        //   ]), [
        //   'sent', 'delivered', 'read', 'responded', 'undelivered', 'clicked', 'unsubscribed', 'failed',
        // ]
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
        'sent', 'delivered', 'read', 'responded', 'undelivered', 'clicked', 'unsubscribed', 'failed',
      ]))
    },

    reorderEvents (arr, index) {
      const temp = [...arr]
      for (let i = 0; i < arr.length; i++) {
        temp[i] = arr[arr.findIndex(e => e.event === index[i])]
      }
      for (let i = 0; i < arr.length; i++) {
        arr[i] = temp[i]
      }

      return arr
    },

    setDefaultColumnsByChannel () {
      switch (this.channel) {
        case 'sms':
          this.sendingDetails.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'event', 'countryIso', 'createdAt', 'externalId', 'message', 'URL', 'mnc', 'network', 'browser', 'os', 'ip']
          break
        case 'mailing':
          this.sendingDetails.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'subject', 'from', 'reply', 'event', 'countryIso', 'createdAt', 'externalId', 'Browser', 'OS', 'IP', 'URL', 'emailType']
          break
        case 'voice':
          this.sendingDetails.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'from', 'event', 'country_iso', 'created_at', 'external_id', 'message', 'duration']
          break
        case 'whatsapp':
          this.sendingDetails.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'from', 'event', 'country_iso', 'created_at', 'external_id']
          break
      }
    },

    setColumnsByChannel () {
      this.trackingEvents = [...this.trackingEventsSource]
      this.sendingDetails.events = []
      switch (this.channel) {
        case 'sms':
          this.setSmsColumns()
          this.setInverseSmsColumns()
          break
        case 'mailing':
          this.setMailingColumns()
          this.setInverseMailingColumns()
          break
        case 'voice':
          this.setVoiceColumns()
          this.setInverseVoiceColumns()
          break
        case 'whatsapp':
          this.setWhatsappColumns()
          // this.setInverseWhatsappColumns()
          break
      }
      if (this.sendingDetails.restrictedEvents?.length > 0) {
        this.trackingEvents = this.trackingEvents.filter(e => this.sendingDetails.restrictedEvents.includes(e.event))
      }
      this.setDefaultColumnsByChannel()
    },

    toggleColumns () {
      if (this.customizeFields) {
        this.sendingDetails.columns = []
      } else {
        this.setColumnsByChannel()
      }
    },
    closeModal () {
      this.showModal = false
      this.customizeFields = false
    },
  },
  watch: {
    sendingDetails: {
      handler () {
        if (this.totalEventValues) {
          if (!this.sendingDetails.events.length) {
            this.disabledCreate = true
            EventBus.$emit('hideAlert')
            return
          }

          let total = 0
          this.sendingDetails.events.forEach(event => {
            total += this.totalEventValues[event]
          })

          if (!total) {
            this.disabledCreate = true
            EventBus.$emit('showAlert', 'warning', this.$t('Los eventos seleccionados no tienen datos por los que filtrar').toString())
          } else {
            this.disabledCreate = false
            EventBus.$emit('hideAlert')
          }
        }
      },
      deep: true,
    },
  },
  created () {
    this.eventPrefix = this.eventPrefix + '.' + this.channel + '.'
    if (this.mode === 'share') {
      TrackingEventsService.getShareEvents(this.$route.params.shareHash)
        .then(
          (response) => {
            this.trackingEventsSource = response.events
            this.setColumnsByChannel()
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
        })
        .finally(() => (this.loading = false))
    } else {
      TrackingEventsService.getEventsByChannel(this.channel)
        .then(
          (response) => {
            this.trackingEventsSource = response.events
            this.setColumnsByChannel()
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
        })
        .finally(() => (this.loading = false))
    }


    StatsService.getCustomFieldsWithSendingId({ sendingId: this.sendingDetails.sendingId, channel: this.channel })
    .then((response) => {
      this.customFields = response.customFields
      this.addCustomFieldsToColumns()
    })

    this.loadedColumns = true

    EventBus.$on('showConfirmCustomFields', (actionMode, totalEventValues) => {
      this.actionMode = actionMode
      this.setColumnsByChannel()
      this.showModal = true
      this.totalEventValues = totalEventValues
    })
  },
}
